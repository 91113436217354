import React, { useState, useEffect } from 'react';
import { ExpressCheckoutElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API_URL } from '../config/stripe';
import './MealOffset.css';

const CARBON_FACTORS = {
  vegetarian: { value: 0.5, label: 'Vegetarian Meal' },
  chicken: { value: 0.7, label: 'Chicken Based Meal' },
  pork: { value: 1.3, label: 'Pork Based Meal' },
  fish: { value: 1.5, label: 'Fish Based Meal' },
  beef: { value: 3.0, label: 'Beef Based Meal' },
};

function MealOffset() {
  const stripe = useStripe();
  const elements = useElements();
  const [mealType, setMealType] = useState('vegetarian');
  const [portions, setPortions] = useState(1);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [carbonEmission, setCarbonEmission] = useState(0);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState('');

  const handlePortionChange = (value) => {
    setPortions(Number(value));
  };

  useEffect(() => {
    const emission = CARBON_FACTORS[mealType].value * portions;
    setCarbonEmission(emission);
  }, [mealType, portions]);

  useEffect(() => {
    if (carbonEmission > 0) {
      const createPaymentIntent = async () => {
        try {
          const response = await fetch(`${API_URL}/stripe/create-payment-intent`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              amount: Math.round(carbonEmission * 50),
              currency: 'eur'
            }),
          });

          const { clientSecret: secret } = await response.json();
          setClientSecret(secret);
        } catch (err) {
          setError('Failed to initialize payment');
        }
      };

      createPaymentIntent();
    }
  }, [carbonEmission]);

  return (
    <div className="meal-offset">
      <div className="meal-offset-form">
        <div className="form-group">
          <label htmlFor="meal-type">Meal Type:</label>
          <select
            id="meal-type"
            value={mealType}
            onChange={(e) => setMealType(e.target.value)}
          >
            {Object.entries(CARBON_FACTORS).map(([key, { label }]) => (
              <option key={key} value={key}>{label}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="portions">Number of Portions: {portions}</label>
          {!showCustomInput ? (
            <>
              <div className="portion-buttons">
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <button
                    key={num}
                    onClick={() => handlePortionChange(num)}
                    className={`portion-button ${portions === num ? 'active' : ''}`}
                  >
                    {num}
                  </button>
                ))}
              </div>
              <button 
                className="more-portions-button"
                onClick={() => setShowCustomInput(true)}
              >
                Need more portions?
              </button>
            </>
          ) : (
            <div className="custom-portions">
              <input
                type="number"
                min="7"
                value={portions}
                onChange={(e) => handlePortionChange(Math.max(7, Number(e.target.value)))}
                className="custom-portions-input"
              />
              <button 
                onClick={() => {
                  setPortions(6);
                  setShowCustomInput(false);
                }}
              >
                Back to buttons
              </button>
            </div>
          )}
        </div>

        <div className="calculation-result">
          <p className="carbon-amount">Carbon Footprint: <span>{carbonEmission.toFixed(2)} kg CO2</span></p>
          <p className="trees-equivalent">Equivalent to <span>{Math.ceil(carbonEmission / 22)} trees 🌳</span></p>
          <p className="price-amount">Offset Cost: <span>€{(carbonEmission * 0.5).toFixed(2)}</span></p>
        </div>

        {clientSecret && carbonEmission > 0 && (
          <div className="payment-section">
            <ExpressCheckoutElement
              mode="payment"
              amount={Math.round(carbonEmission * 50)}
              currency="eur"
              onConfirm={async () => {
                if (!stripe || !elements) return;
                
                const { error } = await stripe.confirmPayment({
                  elements,
                  clientSecret,
                  confirmParams: {
                    return_url: window.location.origin + '/success',
                  },
                });

                if (error) {
                  setError(error.message);
                }
              }}
            />
            {error && <div className="payment-error">{error}</div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default MealOffset;