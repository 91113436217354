import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from './config/stripe';
import MealOffset from './components/MealOffset';
import './App.css';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#27ae60',
  },
};

function App() {
  return (
    <Elements 
      stripe={stripePromise}
      options={{
        appearance,
        mode: 'payment',
        currency: 'eur',
        amount: 50,
        paymentMethodCreation: 'manual',
      }}
    >
      <div className="app">
        <header className="header">
          <h1>Neutro</h1>
          <p>Calculate & Offset Your Meal's Carbon Footprint</p>
        </header>
        <main className="main-content">
          <MealOffset />
        </main>
      </div>
    </Elements>
  );
}

export default App;
